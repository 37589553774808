/* center headings */
.ag-header-cell-label{
    justify-content: center;
 }

 /* do not  show border on cell focus */
 .ag-cell-focus,.ag-cell-no-focus{
    border:none !important;
 }

.ag-cell-inline-editing{
    height:  var(--ag-line-height);
}

.label-width-130{
    width: 130px;
}

.label-width-110{
    width: 110px;
}

.width-1350{
    width: 1500px !important;
}

.width-300{
    width: 300px !important;
}

