.bg-current {
    background-color: #7aeb7a;
}

.bg-expired {
    background-color: #db3232;
}

.bg-override {
    background-color: #e8e841;
}

.membership-data-grid .ag-header {
    display: none;
}

.bg-valid {
    background-color: #22cd22;
}

.bg-special {
    background-color: #ed9c04;
}

.bg-none {
    background-color: #FFFFFF;
}