  @media (min-width: 992px) {
      .masterfee-tab .w-lg-205px {
          width: 205px;
      }
  }

  .delete-icon {
      cursor: pointer;
      margin: 3px 2px 2px 2px;
      position: relative;
      color: #000;
    }

  .icon-pencil {
      cursor: pointer;
      margin: 3px 2px 2px 2px;
      position: relative;
      color: #000;
  }

  span.featuredImage  {
    color: green;
  }

  .image-order {
    position: relative;
    align-items: center;
    justify-content: center;
  }

  div.desc {
    padding: 15px;
    text-align: center;
  }

  .error-msg {
    color: salmon;
  }

  .gallery-container {
    position: relative;
    padding-left: 0px;
    margin-left: 0px;
  }

  .gallery-container input { 
    cursor: pointer;
    margin: 3px 2px 2px 0px; 
    position: relative;  
  }

  .gallery-container .image-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
  }

  div.gallery .popup-image {
    position: fixed;
    background: #ffffff00;
    height: 100%;
    top:0;
    left:0;
    width: 100%;
    z-index: 150;
    overflow: hidden; 
    display: none;
  }
  div.gallery .popup-image .popup-image-background{
    background-color: #000;
    position: fixed;
    z-index: 151;
    width: 100%;
    overflow: hidden;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.8;
  }
  div.gallery .popup-image span{
    position: absolute;
    font-size: 22px;
    font-weight: bold;
    top: 5%;
    right: 5%;
    z-index: 152;
    /* background-color: #000; */
    color: white;
    cursor: pointer;
    padding: 4px 13px;
    border: 50%;
    border: 2px solid black;
    border-radius: 50%;
    border-color: rgb(255, 255, 255);
  }

  div.gallery .popup-image img {
    position: fixed;
    transform: translate(-50%, -50%);
    top: 50%; 
    left: 50%;
    z-index: 152;
    /* border-radius: 5px; */
    /* border: 5px solid white; */
    width: 500px;
    height: auto;
    object-fit: cover;
  }

  div span.message {
      float:right;
      margin-right:10px;
  }

  div span.image-name {
      color: #585d64;
  }

  @media(max-width: 700px){
    div.gallery .popup-image img {
        width: 75%;
        height: auto;
    }
    .next {
      right: 5%;
    }
    .prev {
      left:5%;
    }
  }

  span.field-empty-warning {
    color:red;
    /* float: right; */
  }
  div span a {
    color: #222;
  }

  
  /* Next & previous buttons */
  .prev  {
    cursor: pointer;
    position: absolute;
    z-index: 152;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 2px 9px 2px 9px;
    color: white;
    left: 5%;
    font-weight: bold;
    font-size: 18px;
    border-collapse: separate; 
    overflow: hidden;
    perspective: 1px;
    border: 2px solid black;
    border-radius: 50%;
    border-color: rgb(255, 255, 255);
    /* background-color: #000;  */
    transition: 0.6s ease;
    /* border-radius: 0 3px 3px 0; */
    user-select: none;
  }  
  .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 2px 9px 2px 9px;
    z-index: 152;
    color: white;
    right: 5%;
    font-weight: bold;
    font-size: 18px;
    border-collapse: separate; 
    overflow: hidden;
    perspective: 1px;
    border: 2px solid black;
    border-radius: 50%;
    border-color: rgb(255, 255, 255);
    /* background-color: #000;  */
    transition: 0.6s ease;
    /* border-radius: 0 3px 3px 0; */
    user-select: none;
  }

  .prev:hover,
  .next:hover
  {
    background-color: white; 
  }

  div.gallery .popup-image span:hover
  {
    background-color: white; 
    color: #000;
  }

  div.gallery .popup-image p.image-name {
    position: fixed;
    text-align: center;
    width: 100%;
    bottom: 5%;
    color: white;
    font-size: 18px;
    z-index: 152;
  }

  .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-focused{
    border-color: black !important;
  }
  
  .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred{
    border-color: rgb(161, 165, 183)!important;
  }

  .ck.ck-dropdown__panel {
    max-height: 60vh;
    max-height: var(--ck-editor-dropdown-max-height, 60vh);
    overflow-y: auto;
  }

  .ck-editor__editable {
    max-height: 270px;
  }
  
