#class-detail-prizes-tab #split-column{
    width: 300px;
}

@media (max-width: 1152px){
    #class-detail-prizes-tab #split-column{
        width: 100% !important;
    }
}

/* center headings */
.ag-header-cell-label{
    justify-content: center;
 }

 /* do not  show border on cell focus */
 .ag-cell-focus,.ag-cell-no-focus{
    border:none !important;
 }

.ag-cell-inline-editing{
    height:  var(--ag-line-height);
}


