#entry-checklist-tab{
    background-color: #ecf0f3;
}

.checklist-item{
    background-color: white;
    min-height: 58px;
    border: 1px solid #ddd;
    box-shadow: 1px 1px 1px #ccc;
    border-bottom:none;
    border-right:none;
}

.checklist-item .form-check-custom{
    background-color: #f6f7f9;
    border-right:1px solid #eee;
    width: 70px;
}

.checklist-item .form-check-input.checklist-checkbox{
    border-radius: 100%;
    border: 0px solid #dfe0e4;
    background-size: 60%;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 13 11%27 width=%2713%27 height=%2711%27 fill=%27none%27%3e%3cpath d=%27M11.0426 1.02893C11.3258 0.695792 11.8254 0.655283 12.1585 0.938451C12.4917 1.22162 12.5322 1.72124 12.249 2.05437L5.51985 9.97104C5.23224 10.3094 4.72261 10.3451 4.3907 10.05L0.828197 6.88335C0.50141 6.59288 0.471975 6.09249 0.762452 5.7657C1.05293 5.43891 1.55332 5.40948 1.88011 5.69995L4.83765 8.32889L11.0426 1.02893Z%27 fill=%27%23f5f6fa%27/%3e%3c/svg%3e");
    background-color: #dfe0e4;
    cursor: pointer;
}

.checklist-item .checklist-checkbox.form-check-input:checked[type=checkbox]{
    background-color: #4ed257 !important;
    border-color: #4ed257 !important;
    background-size: 60%;
}

.checklist-item .btn{
    cursor: default;
}

.checklist-item .btn-user{
    max-width: 260px;
}

.checklist-item .verified-date-container{
    width: 180px;
    position: relative;
}

.checklist-item .verified-date-container::after{
    content: "";
    height: 14px !important;
    width: 1px;
    position: absolute;
    right: 0;
    top: 4px;
    background-color: #aaa;
}