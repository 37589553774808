.assign-full-height{
  height:100%;
  padding-left:0px;
}

#barn-designer{
  position:relative;
}

#barn-designer .parameters {
  padding: 10px;
  background-color: #eef1f5;
  width: 210px !important;
  min-width: 210px !important;
  max-width: 210px !important;
}

#barn-designer .parameters label {
  display: block;
  font-size: 12px;
}

#barn-designer .parameters .error-msg {
  font-size: 11px;
  color: red;
  line-height: 15px;
  display: block;
  margin-top: 2px;
}

#barn-designer .parameters form .field-container {
  margin-bottom: 10px;
  background: #ffffff;
  padding: 10px;
  border: 1px solid #dadada;
  box-shadow: 1px 1px 1px #d6d8dc;
  border-radius: 4px;
}

#barn-designer .parameters form .field {
  margin-bottom: 10px;
}

#barn-designer .parameters form .field input[type='number'] {
  border: 1px solid #aaa;
  font-size: 12px;
  display: block;
  width: 100%;
}

#barn-designer #canvas {
  position: relative;
  width: 100% !important;
}

#barn-designer #canvas .zoom-controls {
  position: absolute;
  bottom: 40px;
  left: 20px;
  z-index: 1000;
  background-color: #fff;
  padding: 4px;
  border-radius: 4px;
  box-shadow: 0 0px 6px rgba(0,0,0,0.1);
}

#barn-designer #canvas .zoom-controls button {
  align-items: center;
  background: #eee;
  border: none;
  border-bottom: 1px solid #ddd;
  box-sizing: content-box;
  cursor: pointer;
  display: flex;
  height: 16px;
  justify-content: center;
  padding: 6px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 16px;
  margin-bottom: 2px;
}

#barn-designer #canvas .zoom-controls button:hover {
  background: #e9e8ee;
}

#barn-designer #canvas .zoom-controls button svg {
  width: 12px;
}

#barn-designer .edit-form-overlay {
  position: fixed;
  top: 60px;
  right: 20px;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;
}

#barn-designer .edit-form {
  min-width: 250px;
}

#barn-designer .edit-form h3 {
  margin-bottom: 15px;
  color: #333;
}

#barn-designer .form-group {
  margin-bottom: 15px;
}

#barn-designer .form-group label {
  display: block;
  margin-bottom: 5px;
  color: #666;
}

#barn-designer .form-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

#barn-designer .delete-btn {
  background: #ff4444;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  margin-top: 10px;
}

#barn-designer .delete-btn:hover {
  background: #cc0000;
}

#barn-designer .barn-designer-toolbar {
  padding: 10px;
  background: #eef1f5;
  border-bottom: 1px solid #ddd;
  display: flex;
  gap: 10px;
}

#barn-designer .barn-designer-toolbar button {
  padding: 8px 16px;
  border: 1px solid #eeeef6;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  box-shadow: 1px 1px 0px #d6d8dc;
}

#barn-designer .barn-designer-toolbar button.active {
  background: #e3f2fd;
  border-color: #2196f3;
  color: #2196f3;
}

#barn-designer .editor-sidebar {
  padding: 10px 15px;
  background: #eef1f5;
  overflow-y: auto;
  width: 210px !important;
  min-width: 210px !important;
  max-width: 210px !important;
}

#barn-designer .editor-section {
  margin-bottom: 10px;
}

#barn-designer .element-selection-panel .editor-section{
  margin-bottom: 0px;
}

#barn-designer .editor-section h3 {
  margin-bottom: 7px;
  font-size: 16px;
  color: #333;
}

#barn-designer .shape-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
}

#barn-designer .shape-button,
#barn-designer .text-button,
#barn-designer .image-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  background: white;
  border: 1px solid #eeeef6;
  border-radius: 4px;
  cursor: pointer;
  gap: 0px;
  text-transform: capitalize;
  box-shadow: 1px 1px 0px #d6d8dc;
}

#barn-designer .shape-button span,
#barn-designer .text-button span,
#barn-designer .image-button span {
  font-size: 12px;
  margin-top: 5px;
}

#barn-designer .shape-button:hover,
#barn-designer .text-button:hover,
#barn-designer .image-button:hover {
  background: #f0f0f0;
}

#barn-designer .properties-panel {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

#barn-designer .properties-panel button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
}

#barn-designer .properties-panel button:hover {
  background: #f0f0f0;
}

#barn-designer .toolbar-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1000;
}

#barn-designer .element-toolbar, #barn-designer  .multi-select-toolbar {
  pointer-events: auto;
}

#barn-designer .element-toolbar button:hover, #barn-designer  .multi-select-toolbar button:hover{
  background-color: #f5f5f5;
}

#barn-designer .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001;
}

#barn-designer .modal-content {
  background: white;
  padding: 24px;
  border-radius: 8px;
  min-width: 400px;
  max-width: 600px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

#barn-designer .modal-content h2 {
  margin: 0 0 16px 0;
  font-size: 20px;
}

#barn-designer .modal-body {
  margin-bottom: 24px;
  background-color: #fff;
}

#barn-designer .modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

#barn-designer .design-name-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

#barn-designer .designs-list {
  max-height: 400px;
  overflow-y: auto;
}

#barn-designer .design-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 8px;
  background-color: #f4f8fb;
  position: relative;
}

#barn-designer .design-info h3 {
  margin: 0;
  font-size: 16px;
}

#barn-designer .design-info p {
  margin: 4px 0 0 0;
  font-size: 12px;
  color: #666;
}

#barn-designer button {
  padding: 8px 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px;
}

#barn-designer button.primary {
  background: #4caf50;
  color: white;
  border: none;
}

#barn-designer button:hover {
  background: #f5f5f5;
}

#barn-designer button.primary:hover {
  background: #43a047;
}


#barn-designer .position-menu button.menu-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  border: none;
  background: none;
  width: 100%;
  cursor: pointer;
}

#barn-designer .position-menu button.menu-item:hover {
  background-color: #f5f5f5;
}

#barn-designer .layer-panel {
  height: 100%;
  background: white;
  border-right: 1px solid #eee;
}

#barn-designer .layer-item {
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 8px;
  cursor: move;
  border-bottom: 1px solid #eee;
}

#barn-designer .layer-item:hover {
  background-color: #f5f5f5;
}

#barn-designer .design-item .default-bar{
  position: absolute;
  top:0px;
  left:0px;
  bottom:0px;
  width: 3px;
  background-color: #ccc;
}

#barn-designer .design-item .active-bar{
  position: absolute;
  top:0px;
  left:0px;
  bottom:0px;
  width: 3px;
  background-color: #22c55e;
}

#barn-designer .design-item .active-badge{
  background-color: #dcfce7;
  font-size: 12px;
  margin-left: 15px;
  padding: 2px 6px;
  border: 1px solid green;
  border-radius: 5px;
  color: green;
}


#barn-designer .design-status-label {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 6px;
  font-weight: 500;
  background: var(--label-bg, #EFF6FF);
  color: var(--label-color, #2563EB);
  border: 1px solid var(--label-border, #BFDBFE);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

#barn-designer .design-status-label svg {
  color: currentColor;
}


#barn-designer .design-status-label svg {
  color: currentColor;
}



#barn-designer .element-selection-panel input[type="number"]::-webkit-inner-spin-button,
#barn-designer .element-selection-panel input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: auto;
  margin: 0;
}


#barn-designer input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
  appearance: textfield;
  padding-right: 24px; /* Make room for the custom arrows */
}