#stagger-class-loading{
    color: black;
    font-size: 20px;
}

.stagger-grid span.ag-sort-indicator-container {
    display: none !important;
}

.highlight-stagger-row {
    background-color: #ffffc4 !important;
}
  
.announcer-interface-ui{
    position:relative;
    top:-21px;
}

.announcer-interface-ui .announcer-tool-content{
    padding-bottom: 21px !important;
    background-color: #fff;
}

.announcer-interface-ui .row.mb-0{
    margin-bottom: 2px !important;
}

.announcer-interface-ui fieldset legend{
    font-size: 11px !important;
    margin-bottom: 5px !important;
}

.announcer-interface-ui fieldset legend.less-margin{
    margin-bottom: 1px !important;
}

.announcer-interface-ui .btn.btn-sm.btn-secondary.orange{
    padding: 4px 14px !important;
    font-size: 11px !important;
}

.announcer-interface-ui .btn.btn-sm.btn-secondary.rect{
    font-size: 11px !important;
    height: 18px !important;
    padding: 0px !important;
    line-height: 16px;
    display: flex;
    justify-content: center;
    align-content: center;
}

.announcer-interface-ui .btn.btn-sm.btn-secondary.rect i {
    display: flex;
    align-self: center;
    padding:0px;
}

.announcer-interface-ui .form-select,
.announcer-interface-ui .form-control,
.announcer-split-view .form-select,
.announcer-split-view .form-control{
    font-size:12px !important;
    padding-top: 1px !important;
    padding-bottom: 1px !important;
    height: 18px !important;
    line-height: 14px !important;
}

.announcer-interface-ui .top-form-group .form-select,
.announcer-interface-ui .top-form-group .form-control{
    font-size:13px !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    height: 20px !important;
    line-height: 14px !important;
}

.announcer-interface-ui .top-form-group .col-form-label{
    font-size: 13px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    font-weight: bold;
}


.announcer-interface-ui .col-form-label{
    font-size: 12px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    font-weight: bold;
}

.announcer-split-view .col-form-label{
    font-size: 13px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    font-weight: bold;
}

.announcer-interface-ui .tab-content .btn-secondary{
    padding:5px;
}

.scratched-row .ag-cell{
    color: red;
}
.ingate-grid.ag-theme-alpine .ag-cell{
    line-height: 17px;
    font-size: 12px !important;
    border-right:1px solid #eee !important;
    padding-left:2px !important;
    padding-right:2px !important;
    justify-content: center;
    display: flex;
}

.ingate-grid.ag-theme-alpine .ag-cell input[type="checkbox"], .ingate-grid.ag-theme-alpine .ag-cell .svg-icon{
    cursor: pointer;
    align-self: center;
    display: flex;
}

.ingate-grid.ag-theme-alpine .ag-header-cell, 
.ingate-grid.ag-theme-alpine .ag-header-group-cell{
    padding-left:5px;
    padding-right:5px;
    font-size: 12px !important;
}

.ingate-grid.ag-theme-alpine .ag-header-cell .ag-cell-label-container,
.ingate-grid.ag-theme-alpine .ag-header-cell .ag-header-group-cell{
    padding:0px;
}


.announcer-interface-ui .form-check-input{
    cursor: pointer;
}
.selected-row{
    background-color: rgba(33, 150, 243, 0.2) !important;
}

.remainingtrips,.staggers{
    position:relative;
}


.remainingtrips .remainingtrips-overlay {
    position: absolute;
    display: block; 
    width: 100%; 
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 2;
    opacity: 0.3;
}

.staggers .staggers-overlay{
    position: absolute;
    display: block; 
    width: 100%; 
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.staggers .staggers-overlay .background{
    background-color: #fff;
    z-index: 2;
    opacity: 0.3;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.staggers .staggers-overlay .messages{
    z-index: 3;
    position: absolute;
    top: 75px;
    margin-left:10px;
    margin-right:20px;
    background: #fff;
}

.staggers .staggers-overlay .messages p{
    color:red;
    font-weight:bold;
    margin:0px 0px 5px 0px;
    font-size: 17px;
    line-height: 17px;
}

#kt_body button.btn-secondary.r2_start{
    color: #0da250 !important;
    text-transform: none;
}

.warning-trips-set-of-quickly{
    text-align: center;
    font-size: 14px;
    margin-top: 5px;
    color: red;
    font-weight: bolder;
}

.ingate-scoreboard .form-select
{
    height: 30px !important;
}

.ingate-scoreboard .btn.btn-sm{
    padding: 4px 14px !important;
    font-size: 11px !important;
    min-width: 122px;
}

.ingate-scoreboard .scoreboard-messages .form-control{
    height: 30px !important;
    font-size: 14px !important;
}

.ingate-scoreboard fieldset legend {
    font-size: 14px !important;
    letter-spacing: 0.6px;
    margin-bottom: 10px !important;
    padding-bottom: 5px !important;
    padding-top: 5px !important;
}

@media (max-width: 991px){
    .announcer-interface-ui{
        top:-48px
    }

    .announcer-interface-ui .announcer-tool-content{
        padding-bottom: 48px !important;
    }
}