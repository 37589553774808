#classrules_detail_page .attributes-grid-container {
    width: 55.5%;
}

#classrules_detail_page .attributes-grid {
    border-color: #babfc7 !important;
}

#classrules_detail_page .attributes-header {
    background-color: #F9F8F8;
    border-color: #babfc7 !important;
}

#classrules_detail_page .attribute-heading-separator {
    width: 1px !important;
    padding: 0px;
    cursor: ew-resize;
}

#classrules_detail_page .attribute-heading-separator::after {
    content: "";
    position: relative;
    z-index: 1;
    display: block;
    left: calc(50% - 1px);
    width: 1px;
    height: 40%;
    top: calc(50% - 15%);
    box-sizing: border-box;
    outline: none;
    background-color: #000 !important;
}