.notes .note-list {
    background: #F9F8F8;
    border: 1px solid #ccc;
}

.notes .note-list .list-note:last-child{
    border-bottom:none;
}

.notes .list-note{
    border-bottom: 1px solid #d3cece;
}

.notes .list-note .note-text-ellipse{
    display: -webkit-box;
    margin: 0 auto;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.notes .list-note .text-overflow{
    color: #2274a5;
    cursor: pointer;
}

.notes .list-note .edit, .notes .list-note .delete{
    cursor: pointer;
}