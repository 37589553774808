.ag-cell {
    color: #000000; 
}

.ag-select {
    line-height: 14px;
}

.link-color {
    color: rgb(34, 116, 165);
}