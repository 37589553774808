.page-title {text-transform: uppercase;}
.ag-header-cell-text{text-transform: uppercase;}
fieldset legend b,
fieldset legend b{flex: 1 1;height: 1px;align-self: center;margin-left: 10px;margin-top: 1px;background: #9fd1fb; display:none}

fieldset legend,
fieldset legend{ background-color:#2274a5;color:#fff;padding:1px 8px; text-transform: uppercase;box-shadow:2px 2px 0px #fcb073}

div legend,
div legend{ background-color:#2274a5;color:#fff;padding:1px 8px; text-transform: uppercase;}

.form-check-input,
.form-check-input{background-color:#fff;}

.form-check-input:checked[type="checkbox"],
.form-check-input:checked[type="radio"],
.form-check-input:checked[type="checkbox"],
.form-check-input:checked[type="radio"]{background-color: #888 !important;border-color: #888;background-size: 88%;}

.form-check-sm.form-check-custom input,
.form-check-sm.form-check-custom input{height: 15px !important;width: 15px !important;}

.modal .modal-header h2,
.modal-header h2{color:#2274a5;text-transform:uppercase}

.modal-body{background-color:var(--kt-input-solid-bg);}

.red{color:red;}
.blue{color:blue;}
.nav.nav-tabs .nav-link.active{background-color:#2274a5 !important;color:#fff !important;}
.flatpickr-month{border-radius:0px !important;}

.card-footer.input-form-buttons{background-color: var(--kt-page-bg);position: fixed;bottom: 0px;right: 0px;left: 0px; z-index: 2; }
.card-footer.input-form-buttons .input-form-buttons-inner{max-width:1475px;}

.input-group-text,
.input-group-text{height:29.5px;padding: 8px;}

.form-check-input:disabled,
.form-check-input:disabled{background-color: #ddd;}

.nav-tabs.nav-pills{border-bottom: 1px solid #b6b8bf;}
.nav-pills .nav-item{border: 1px solid #b6b8bf;border-bottom: none;box-shadow:1px 1px 1px #ccc;}
.nav-pills .nav-item a{background-color: var(--kt-secondary);text-transform: uppercase;}
.nav-pills .nav-item a:hover{background-color:#eff1f8 !important;}
.date-label{cursor:pointer;}

.show-input-form #GENERAL label:not(.ignore-max-width){max-width:134px;}
// .show-input-form #WEB label:not(.ignore-max-width){max-width:184px;}
// .show-input-form #ADDITIONAL label:not(.ignore-max-width){max-width:184px;}

.form-control, .form-select, .form-check-input, .input-group-text, .input-group-text{border-color: var(--kt-input-focus-border-color) !important; color:var(--kt-form-label-color) !important;}
.form-control:focus, .form-select:focus, .form-check-input:focus{border-color: #1c1c20 !important;}
.input-group-text, .input-group-text{background-color: #fcb07e;}
.input-group-text i {color:#000;}
.ag-center-aligned-header .ag-header-cell-label{justify-content: center;text-align: center;}
.ag-left-aligned-cell{justify-content: start !important; text-align: left !important;}
.ag-left-aligned-header .ag-header-cell-label{justify-content: start !important; text-align: left !important;}
.ag-floating-top {
    overflow-y: hidden !important;
}

.grid-classgroups .ag-theme-alpine .ag-header-cell,
.grid-classgroups .ag-theme-alpine .ag-header-group-cell,
.grid-schedule .ag-theme-alpine .ag-header-cell,
.grid-schedule .ag-theme-alpine .ag-header-group-cell,
.og-for-horse-grid .ag-theme-alpine .ag-header-cell,
.og-for-horse-grid .ag-theme-alpine .ag-header-group-cell,
.og-for-persons-grid .ag-theme-alpine .ag-header-cell,
.og-for-persons-grid .ag-theme-alpine .ag-header-group-cell,
.pl-r-5 {
        padding-left:  5px !important;
        padding-right: 5px !important;
}

.pl-r-2 {
    padding-left:  2px !important;
    padding-right: 2px !important;
}

.form.max-width{
    max-width:1400px;
}

.ag-theme-alpine .ag-root-wrapper-body .ag-center-cols-container{
    min-width:100% !important;
}

.loading-message{
    position: absolute;
    z-index:9999;
    font-size:14px;
}

.loading-message .background{
    position: fixed;
    background: rgba(255,255,255,0.7);
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
}

.loading-message .foreground{
    position:fixed;
    top:50%;
    left:50%;
    height:30px;
    width:300px;
    margin-left:-150px;
    margin-top:-15px;
    display:block;
    text-align:center;
    line-height:30px;
}

.flash-alert-container{
    z-index: 9999;
    right: 10px !important;
    top: 10px !important;
}

/* Style overrides for all input forms */

.input-form .form-control, .input-form .form-select, .input-form .form-check-input, .input-form .input-group-text{
    //border-color: #000 !important;
    //font-size:12px !important;
}

.input-form .form-control:focus, .input-form .form-select:focus, .input-form .form-check-input:focus{
    //border-color: #8e8ea3 !important;
}

.input-form .form-control, .input-form .form-select{
    //padding-top:15px !important;
    //padding-bottom:15px !important;
}   

.input-form .input-group-text, .input-group-text {
    //height:20px !important;
    //padding:10px !important;
}

.input-form label.col-form-label{
    //font-size:12px !important;
    //padding-top:5px !important;
    //padding-bottom:5px !important;
    //font-weight:bold !important;
}

.input-form  label.col-form-label{
    //font-weight:normal !important;
}

.input-form .btn-secondary{
    border: 1px solid #DAD6D6 !important;
}

.input-form .btn.btn-secondary, .input-form .btn.btn-secondary i{
    //background-color: #000 !important;
    //color: #fff !important;
    //font-size:12px !important;
    //height:30px !important; 
    //width:150px !important; 
}

.input-form .btn.btn-dark{
    //background-color: #000 !important;
    //color: #fff !important;
    //font-size:12px !important;
    //height:30px !important; 
    //width:150px !important; 
}

/* END Style overrides for all input forms */


/* Style overrides for all search forms */
.search-form .form-control, .search-form .form-select, .search-form .form-check-input, .search-form .input-group-text{
    //border-color: #000 !important;
    //font-size:12px !important;
}

.search-form .form-control:focus, .search-form .form-select:focus, .search-form .form-check-input:focus{
    //border-color: #8e8ea3 !important;
}

.search-form .form-control, .search-form .form-select{
    //padding-top:15px !important;
    //padding-bottom:15px !important;
}   

.search-form .input-group-text, .input-group-text {
    //height:20px !important;
    //padding:10px !important;
}

.search-form label.col-form-label{
    //font-size:12px !important;
    //padding-top:5px !important;
    //padding-bottom:5px !important;
    //font-weight:bold !important;
}

.search-form  label.col-form-label{
    //font-weight:normal !important;
}

.search-form .btn-secondary{
    border: 1px solid #DAD6D6 !important;
}

.search-form .btn.btn-secondary, .search-form .btn.btn-secondary i{
    //background-color: #000 !important;
    //color: #fff !important;
    //font-size:12px !important;
    //height:30px !important; 
    //width:150px !important; 
}

.search-form .btn.btn-dark{
    //background-color: #000 !important;
    //color: #fff !important;
    //font-size:12px !important;
    //height:30px !important; 
    //width:150px !important; 
}
/* END Style overrides for all input forms */

.ag-center-aligned-header .ag-header-cell-label {
    justify-content: center;
}

.number-input::-webkit-input-placeholder {
    text-align: left;
}
  
.number-input:-moz-placeholder {
    text-align: left;
}

.number-input {
    text-align: right;
}

.menu-title-dark{
    border-radius: 0px;
}

.menu-title-dark:not(:empty){
    border:1px solid #ccc;
}

.menu-title-dark .menu-item{
    border-bottom:1px solid #eee;
}

.menu-title-dark .menu-item:hover{
    background:rgb(254, 222, 201);
}

.menu-title-dark .menu-item:last-child{
    border-bottom: none;
}

.verify-entries-icon{
    height:26px;
}
.error_message{
    color: var(--kt-primary);
}
.success_message{
    color: var(--kt-text-success) !important;
}
.ml-20{
    margin-left: 20px;
}


// to remove increment in input type number
// For chrome, Safari, Edge, Opera :
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
// For FireFox
input[type=number]{
    -moz-appearance: textfield;
}

.expired-text.ag-row, .expired-text.ag-row .ag-cell, .expired-text.ag-cell, .expired-text.ag-row .editable-cell{
    color: var(--kt-primary) !important;
}

.bg-red-grid-row.ag-row  {
    background-color: #ff6d6d !important;
}
.bg-current.ag-row {
    background-color: #a1f1a1 !important;
}

.bg-expired.ag-row, .bg-expired.ag-cell {
    background-color: #ff8c81 !important;
}

.bg-override.ag-row {
    background-color: #f7eb59 !important;
}

.bg-upcoming-r2.ag-row {
    background-color: #00ffbf !important;
}

.border-upcoming-r2{
    border-color: #00ffbf !important;
}

.membership-data-grid .ag-header {
    display: none;
}

.bg-valid.ag-row {
    background-color: #a1f1a1 !important;
}

.bg-special.ag-row {
    background-color: #f9a83e !important;
}

.bg-none.ag-row {
    background-color: #FFFFFF !important;
}

.custom-dialog {
    max-width: 1000px;
}

.modalWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, var(--kt-modal-backdrop-opacity)) !important;
    z-index: 999;
}

.form-label{
    margin-bottom: 0;
}

.apply-payments-modal {
    max-width: 94vw;
}

.apply-withholding-tax-modal {
    max-width: 1400px;
}

.payment-rollback-modal {
    max-width: 85vw;
}

.rollforward-balance {
    max-width: 83vw;
}

.transfer-balance {
    max-width: 77vw;
}

.check-reversed-amount {
    max-width: 700px;
}

.view-preview-modal{
    max-width: 100%;
}

.ag-grid-red-row-font {
    color: #ff0000!important;
}

.verify-roster-modal {
    max-width: 1135px;
}

.fee-splits-modal {
    max-width: 1100px;
}

.modal-1100px-max-width {
    max-width: 1100px;
}

.modal-1400px-max-width {
    max-width: 1400px;
}

.modal-900px-max-width {
    max-width: 900px;
}

.modal-950px-max-width {
    max-width: 950px;
}

.refund-radio {
    max-width: 125px;
}

.label-width-180 {
    width: 180px;
}

#kt_wrapper #kt_content #kt_content_container > .modal-body{
    margin-top:-20px;
}

#kt_page_title{
    margin-left:15px;
}

span.flatpickr-weekday, .flatpickr-current-month .flatpickr-monthDropdown-months, .flatpickr-current-month input.cur-year{
    color:#fff !important;
    font-weight:normal !important;
}

.flatpickr-months .flatpickr-month, .flatpickr-current-month .flatpickr-monthDropdown-months, .flatpickr-weekdays, span.flatpickr-weekday,
.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay
{
    background:#2274a5 !important;
    border-color: #2274a5 !important;
}

.flatpickr-calendar.arrowTop::after {
    border-bottom-color: #2274a5 !important;
}

.flatpickr-calendar.arrowBottom::after {
    border-top-color: #2274a5 !important;
}

.flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg {
    fill: #fcb07e !important;
}

.ag-theme-alpine .ag-header{
    background-color:#F9F8F8 !important;
}

.card-title .btn-secondary, .card-toolbar .btn-secondary, .form .btn-secondary, .tab-content .btn-secondary, #kt_body .btn-secondary{
    border: 1px solid #9da0a9 !important;
    color: #161618 !important;
}

#kt_content_container .input-form .btn-secondary:hover,
#kt_content_container .search-form .btn-secondary:hover,
#kt_content_container .card-title .btn-secondary:hover,
#kt_content_container .card-toolbar .btn-secondary:hover,
#kt_content_container .form .btn-secondary:hover,
#kt_body .btn-secondary:hover{
    background-color:#eff1f8 !important;
}   

.scheduler.modal-body{
    padding-top:5px !important;
}

.ag-theme-alpine .ag-header-cell-resize::after{
    background-color:#000 !important;
}

.ag-theme-alpine .ag-header-cell:last-child .ag-header-cell-resize::after{
    background-color:transparent !important;
}

.loading-bar{
    border: 1px solid #666;
    font-weight: bold;
    background: rgb(248, 248, 248);
    padding: 10px !important;
    box-shadow: none !important;
}

.css-1ubdp1u-control{
    border-color:#A1A5B7 !important;
}

.css-1ubdp1u-control svg{
    fill: #80839a;
}

_::-webkit-full-page-media, _:future, :root .input-group-text {
    height:30px !important;
}

_::-webkit-full-page-media, _:future, :root .entries-search-legend{
    display:inline-flex !important;
}

_::-webkit-full-page-media, _:future, :root .entries-search-legend > div, :root .entries-search-legend > label {
    display:inline-flex !important;
    vertical-align:middle;
}

.note-list {
    background: #F9F8F8;
    border: 1px solid #ccc;
}

.note-list .list-note:last-child{
    border-bottom:none;
}

.checklist-item{
    border: 1px solid #ddd;
    box-shadow: 1px 1px 1px #ccc;
    border-bottom:none;
    border-right:none;
}

.checklist-item input{
    cursor: pointer;
}

.checklist-item .form-check-custom{
    border-right:1px solid #eee;
}

.checklist-item .verified-date-container{
    position: relative;
}

.checklist-item .verified-date-container::after{
    content: "";
    height: 14px !important;
    width: 1px;
    position: absolute;
    right: 0;
    top: 4px;
    background-color: #aaa;
}

#dashboard{
    background: #fcfcfc;
    height: calc(100% + 20px);
    margin-left: -5px;
    margin-top: -5px;
    margin-top: -21px;
}

#dashboard .widget{
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
    background: #fff !important;
    border: 1px solid #F1F1F4 !important;
    border-radius: 8px;
    height:200px;
}

#dashboard .widget.no-height-limit{
    height:auto;
}

.form-select#main-show-picker{
    color: #000000 !important;
}

.country-code-picker{
    height: 30px !important;
}

.country-code-picker input[type=text], .country-code-picker input[type=tel]{
    height: 30px !important;
    position: relative !important;
}

[id^="react-select"] ~ [class$="menu"] {
    z-index: 2;
}

.intl-tel-input.allow-dropdown .selected-flag {
    width: 41px !important;
}

.intl-tel-input.allow-dropdown input, .intl-tel-input.allow-dropdown input[type=text], .intl-tel-input.allow-dropdown input[type=tel] {
    padding-left: 41px !important;
    padding-right: 3px !important;
}

.phone-column{
    min-width: 158px;
}
.tooltip-display::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 9px solid #57595d; /* Change border-top to create the triangle at the bottom */
    left: 9px; //calc(50% - 7px); /* Adjust to position the triangle in the center horizontally */
    bottom: -9px; /* Adjust to position the triangle at the bottom of the tooltip */
}

.btn:disabled, .btn.disabled, fieldset:disabled .btn{
    opacity: 0.45 !important;
}

.connected-login{
    border: 1px solid #a1a5b7;
    background: #fff;
    box-shadow: 1px 1px 3px #ccc;
    border-radius: 0px;
    padding: 5px 10px;
}

.connected-login .linked-label, .connected-login-entries .linked-label{
    color: #2c489c;
    text-transform: uppercase;
}

.connected-login .linked-value, .connected-login-entries .linked-value{
    text-transform: uppercase;
}

.connected-login i, .connected-login-entries i{
    font-size:1.2em;
}

.connected-login a, .connected-login-entries a{
    text-decoration: underline;
}

.connected-login .fa-circle-exclamation, .connected-login-entries .fa-circle-exclamation{
    color: orange !important;
}

#myTabContent{
    position: relative;
}

#myTab{
    margin-right:38px !important;
}

.help-link{
    font-size: 12px;
    line-height: 22px;
    color: #000;    
    padding: 0px 5px;
    box-shadow: 1px 1px 2px #666;
    border-radius: 5px;
    background: #f7f7f7;
    position: absolute;
    top: -3.5rem;
    right: 0px;
    display:none;
}

.help-link:hover{
    background:#fff;
    color:#e62721;
}

.help-link.top{
    right: 1.5rem;
    z-index: 99;
    position: fixed;
    top: 5rem;
}


.help-link-icon{
    height:20px;
    width:20px;
    margin-left: 3px;
    margin-top: -3px;
}

.ag-header-cell .ag-header-cell-label .ag-icon.ag-icon-filter{
    color:red;
}

.add-refine-wrapper{
    width:428px;
}

.ingate-grid .ag-drag-handle.ag-row-drag{
    margin:0px auto;
    display:flex !important;
}

.menu-item{
    cursor:pointer;
}

.menu-item.menu-accordion > .menu-link:not(.active) .menu-title{
    color: var(--kt-text-gray-800) !important;
}

.menu-item.menu-accordion > .menu-link:not(.active) .menu-icon i{
    color: var(--kt-text-muted) !important;
}

.menu-item.menu-accordion > .menu-link:hover .menu-title, .menu-item.menu-accordion > .menu-link:hover .menu-icon i{
    color: var(--kt-primary) !important;
}

.menu-item.menu-accordion .menu-sub {
    max-height: 0;
    height:0;
    overflow: hidden;
    transition: max-height 0.8s ease;
    display: flex;
}

.menu-item.menu-accordion .menu-sub.show {
    display: flex;
    max-height: 1000px;
    height:auto;
}

#kt_content_container.no-user-select{
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
}

.ag-grid-row-selector{
    background: #2274a5 !important;
    color: #fff !important;
    border: 1px solid #555 !important;
    margin-bottom: 2px;
    border-radius: 3px !important;
    font-weight:bold;
}

.designer-button {
    background: linear-gradient(to bottom, #f8f8f8 0%, #e8e8e8 100%);
    border: 1px solid #b0b0b0;
    border-radius: 3px;
    color: #70747a;
    text-decoration: none;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 1);
    padding: 4px 8px;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    display: inline-block;
    line-height: 1.2;
    transition: box-shadow 0.2s, border-color 0.2s, background-color 0.2s;
}

.designer-button:hover{
    border-radius: 3px;
    box-shadow: 0 1px 1px rgba(0,0,0,0.2), inset 0 1px 0 #fff;
    background: #f0f2f5;
    border-color: #c5c5c5;
}


@media (min-width: 992px) {
    .w-lg-145px{
        width: 145px !important;
    }
    .w-lg-130px{
        width: 130px !important;
    }
    
    .w-lg-68px{
        width: 68px !important;
    }
    
    .w-lg-577px{
        width: 577px !important;
    }
    
    .w-lg-115px{
        width: 115px !important;
    }
    .w-lg-155px{
        width: 155px !important;
    }
}

@media all and (max-width: 1320px) {
    .phone-column{
        min-width: auto;
    }
}

@media all and (max-width: 991px) {
    .user-badges {
        bottom:21px !important;
    }
    .default-page-title{
        margin-top:10px
    }
}


@media all and (max-width: 767px) {
    .verify-entries-icon{
        height:23px;
    }

    .help-link.top{
        top:5.25rem;
    }

    .add-refine-wrapper{
        width:418px;
    }
}

@media screen and (min-width: 1450px) {
    .form.max-width{ max-width: 99%;}
    .card-footer.input-form-buttons .input-form-buttons-inner{max-width: 100%;}
}

i.fa-circle-info,
i.fa-lock,
a.text-danger,
a.text-success {
    cursor: pointer;
}

.ag-theme-alpine .ag-cell.editable-cell {
    height: var(--ag-line-height);
}

.ag-theme-alpine .ag-root-wrapper .editable-cell {
    border-left: 1px solid var(--ag-row-border-color) !important;
    border-right: 1px solid var(--ag-row-border-color);
}

.ag-theme-alpine .ag-root-wrapper .non-editable-cell {
    border-left: 1px solid var(--ag-row-border-color) !important;
    border-right: 1px solid var(--ag-row-border-color);
}

.ag-theme-alpine .ag-cell.editable-cell {
    height: var(--ag-line-height);
    cursor: text;
    border-bottom: 1px solid var(--ag-row-border-color)!important;
    background-color: #f9f9f9;
}

.ag-theme-alpine .ag-cell.editable-cell.ag-cell-focus.ag-cell-inline-editing{
    padding:0px !important;
}

.ag-theme-alpine .ag-cell-inline-editing {
    height: var(--ag-line-height) !important;
}

.bg-red-grid-row.ag-row .editable-cell{
    background-color: #ff6d6d !important;
}

.bg-current.ag-row .editable-cell{
    background-color: #a1f1a1 !important;
}

.bg-expired.ag-row .editable-cell{
    background-color: #ff8c81 !important;
}

.bg-override.ag-row .editable-cell{
    background-color: #f7eb59 !important;
}

.bg-valid.ag-row .editable-cell{
    background-color: #a1f1a1 !important;
}

.bg-special.ag-row .editable-cell{
    background-color: #f9a83e !important;
}

.bg-none.ag-row .editable-cell{
    background-color: #f9f9f9 !important;
}

.membership-data-grid .ag-theme-alpine .ag-row-selected  {
    --ag-selected-row-background-color: #B3D9FF !important;
}

.ag-theme-alpine .ag-row-selected .editable-cell{
    background-color: white !important;
    border-color: var(--ag-input-focus-border-color) !important;
}

.text-danger {
    color: var(--kt-primary) !important;
}

.btn-sm {
    text-transform: uppercase;
}

.modal-backdrop {
    --bs-backdrop-zindex: 1055 !important;
}

@media (min-width: 992px) {
    #cicuit-divisions-input-form .w-lg-160px {
        width: 160px;
    }

    #cicuit-divisions-input-form .w-lg-110px {
        width: 110px;
    }
}

@media (max-width: 1152px){
    #circuit-divisions-standings-tab #print-current-standing-btn{
        padding-top: 1.25rem;
    }
}

.split-horizontal.split-top {
    background:#fff;
    flex-direction: row;
    display: flex;
}

.split-horizontal.split-bottom {
    flex-direction: row;
    min-height: 600px;
    display: flex;
    height: 100%;
    background:#fff;
}

.split-horizontal.split-full {
    flex-direction: row;
    min-height: 600px;
    display: flex;
    height: 90%;
    background:#fff;
}

.split-vertical{
    width:100%;
    height:100%;
}

.gutter {
    background-color: #dedede;
    background-repeat: no-repeat;
    background-position: 50%;
}

.gutter.gutter-horizontal {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
    cursor: col-resize;
}

.gutter.gutter-vertical {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=');
    cursor: row-resize;
}

.scoring .toggle-button{
    margin-top:-2.75rem !important;
}

.scoring .btn.btn-sm.btn-secondary.orange{
    background: #fcb073;
}

.no-transform {
    text-transform: none;
}

.square-shape-ingate{
    background-color: #00ffbf !important;
    height: 15px;
    width: 15px;
    margin-top: 9px !important;
    padding: 0px;
}

.square-shape-ordergo{
    background-color: #50cd89 !important;
    height: 15px;
    width: 15px;
    margin-top: 2px !important;
    padding: 0px;
}

.button-no-wrap{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ag-theme-alpine .image-cell {
    border-right-width: unset !important;
}

.border-bottom-black {
    border-bottom: 1px solid black !important;
}

.pl-5 {
    padding-left: 1.15rem !important;
}

#kt_toolbar_container{
    position: relative;
}

.user-badges{
    position: absolute;
    right: 0px;
    bottom: 7px;
    margin-right: 50px;
}

.user-badges .user-badge{
    height:28px;
    width:28px;
    font-size: 12px;
    background-color:#000;
    border-radius: 50%;
    line-height:28px;
    border: 2px solid #fff;
    box-sizing: content-box;
    cursor:default;
}

.user-badges .user-badge:not(:first-child){
    margin-left:-10px;
}

.user-badges .user-badge span{
    color:#fff !important;
    font-weight:bold !important;
}

.user-badges .user-badge img{
    height: 30px;
    width: 30px;
    display: block;
    border-radius: 50%;
}

.user-badges .user-badge.badge-no-0, .symbol-0{
    background-color:#ff5251 !important;
}

.user-badges .user-badge.badge-no-1,
.user-badges .user-badge.badge-no-6,
.symbol-1,
.symbol-6{
    background-color:#304ffe !important;
}

.user-badges .user-badge.badge-no-2,
.user-badges .user-badge.badge-no-7,
.symbol-2,
.symbol-7{
    background-color:#2ecd6f !important;
}

.user-badges .user-badge.badge-no-3,
.user-badges .user-badge.badge-no-8,
.symbol-3,
.symbol-8{
    background-color:#bf4acc !important;
}

.user-badges .user-badge.badge-no-4,
.user-badges .user-badge.badge-no-9,
.symbol-4,
.symbol-9{
    background-color:#ffa12f !important;
}

.user-badges .user-badge.badge-no-5,
.user-badges .user-badge.badge-no-10,
.symbol-5,
.symbol-10{
    background-color: #7f77f1 !important;
}

.symbol-more{
    background-color: #000000 !important;
}

.symbol-group .symbol-label::after{
    border:none;
}

.toggle-switch {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.toggle-switch input {
    display: none;
}

.toggle-switch .slider {
    width: 50px;
    height: 24px;
    background-color: #ccc;
    border-radius: 12px;
    position: relative;
    transition: 0.4s;
    margin-top:2px;
}

.toggle-switch input:checked + .slider {
    background-color: #2196F3;
}

.toggle-switch .slider:before {
    content: '';
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    left: 2px;
    top: 2px;
    transition: 0.4s;
}

.toggle-switch input:checked + .slider:before {
    transform: translateX(26px);
}

.toggle-switch .label {
    margin-left: 10px;
    margin-right: 10px;
}

.card .card-header .card-toolbar{
    align-items: start;
}

.card .card-header .card-title{
    margin-bottom: 0.1rem;
}

.add-refine{
    padding: 0px 0.50rem 0.3rem 0rem;
}

.add-search-radio:disabled + .add-search-label {
    color: #a7a7ae;
}

.placing-info-wrapper {
    display: inline-block;
    padding: 10px 20px;
    background: #f4f8fb;
    border: 1px solid #a1a5b7;
}

.placing-info-wrapper label{
    color:#000;
    display:flex;
    align-items: center;
    text-shadow: 1px 1px #fff;
}

.placing-info-wrapper .col-form-label{
    width:70px;
    justify-content: center;
}

.placing-info-wrapper input{
    text-align: center;
    border:none;
    //box-shadow: 1px 1px 2px #999 !important;
    margin: 5px 0px;
    border:1px solid #ccc !important;
}

.placing-info-wrapper .placing-row{
    background: #fff;
}

.placing-info-wrapper .placing-row label{
    color:#000;
    background-color: #f4f8fb;
}

.placing-info-wrapper .placing-row input{
    border:1px solid #555 !important;
}

.placing-info-wrapper .row {
    padding:0px;
}

.placing-info-wrapper .row .input-wrapper{
    display:flex;
    padding:0px;
}

.placing-info-wrapper .row .input-wrapper > div{
    padding:0px;
    margin:0px 5px 0px 5px;
    width:65px;
}
.custom-ag-grid-header {
    padding-left: 4px !important;
    padding-right: 4px !important;
}

.fei-prize-template-modal {
    max-width: 450px;
}

#togglePassword{
    cursor: pointer;
    position: absolute;
    right: 10px;
    top:0px;
    transform: translateY(12.5px);
    -webkit-transform: rotate(13px);
    -moz-transform: rotate(12.5px);
    -ms-transform: rotate(13px);
    -o-transform: rotate(12.5px);
}

#login-email:invalid, #login-email.is-invalid,
#login-password:invalid, #login-password.is-invalid,
#login-email:valid, #login-email.is-valid,
#login-password:valid, #login-password.is-valid{
    background-position: right calc(0.375em + 2rem) center;
    padding-right: calc(1.5em + 3rem);
}

.ag-details-row {
    padding: calc(var(--ag-grid-size) * 3) !important;
}

.menu-sub-dropdown.quick-action-dropdown{
    max-height: calc(100vh - 150px);
    overflow-y: scroll;
}

#dashboard_active_user_dialog,
#dashboard_active_user_dialog .modal-content,
#dashboard_active_user_dialog .modal-header,
#dashboard_active_user_dialog .modal-body{
    border:1px solid #fff !important;
    border-radius:7px !important;
}

.custom-truncate {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.spinner-container {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    pointer-events: none;
}

.rings.widget table td span{
    cursor: default;
}

.calendar.widget .fc-event{
    cursor:pointer;
}

#dashboard .nav .nav-link.active{
    background-color: #f1f1f4 !important;
    color:#f85252 !important;
}

#dashboard_active_user_dialog .fa-circle{
    animation: blink 2s ease-in infinite;
}

.calendar.widget .fc-daygrid-block-event .fc-event-time{
    display:none;
}

.calendar.widget .fc-h-event .fc-event-title-container, .fc .fc-daygrid-dot-event .fc-event-title{
    font-weight:bold;
}

.calendar.widget .fc .fc-daygrid-event-dot, .fc-direction-ltr .fc-daygrid-event .fc-event-time{
    display:none;
}

.calendar.widget .fc-daygrid-dot-event{
    background-color: #fd9800;
    color:#fff;
}

@keyframes blink {
  from, to { opacity: 1 }
  50% { opacity: 0 }
}

.square-shape-payment-batch{
    background-color: #ff8c81 !important;
    height: 5px;
    width: 30px;
}

.payment-batch.ag-theme-alpine .ag-header-cell, 
.payment-batch.ag-theme-alpine .ag-header-group-cell{
    font-size: 12px !important;
}

.distribution-detail .ag-group-expanded {
    display: none !important
}

.distribution-detail .ag-group-contracted {
    display: none !important
}

.parent-guardian-select.w-lg-185px {
    width: 195px !important;
}

.link-color {
    color: #2c489c;
}

.disable-reset-search {
    opacity: 0.5; 
    pointer-events: none; 
}

.rto-summary {
    cursor: pointer;
}

.rto-summary.dotted-heading {
    // background-color: #dddddd;
    display: flex;
    align-items: center;
    font-weight: bold; /* Adjust as needed */
}

.rto-summary.dotted-heading::after {
    content: ' ';
    flex-grow: 1;
    border-bottom: 1px dotted black;
    margin-left: 10px;
}

.rto-summary.separator-heading {
    // background-color: #dddddd;
    display: flex;
    align-items: center;
    font-weight: bold; 
}

.rto-summary.separator-heading::after {
    content: ' ';
    flex-grow: 1;
    margin-left: 10px;
    border-bottom: 1px solid #b6b8bf;
    box-shadow: 1px 1px 1px #ccc;
}

.draggable-item {
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    background-color: #ffffff;
    border: 1px solid #92929f;
    border-radius: 0.25rem;
}

.date-placeholder::placeholder {
    font-size: 0.82rem;
}

.rto-detail-w-170 {
    width: 170px;
}

.custom-add-to-show-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

.custom-add-to-show-button {
    line-height: 1 !important;          
    padding: 8px 8px !important;   
    height: auto !important;            
    white-space: nowrap !important;     
    display: inline-block !important;     
    font-size: 12px !important;           
    vertical-align: middle !important;    
}

.disabled-input {
    background-color: #eff2f5 !important; 
    color: #6c757d; 
    cursor: not-allowed;
}