
  div.gallery {
    margin: 5px 5px 5px 0px;
    /* border: 1px solid #ccc; */
    float: left;
    width: 200px;
    height: 200px;
  }

  div.gallery input { 
    cursor: pointer;
    margin: 3px 2px 2px 0px; 
    position: relative;  
  }

  div.gallery img {
    width: 100%;
    height: 140px;
    border: 1px solid #777;
  }
  div.gallery img:hover { 
    border: 1px solid #005dc7;
    box-shadow: 0 0 7px rgb(86 149 219 / 69%);
  }

  div.gallery .popup-image img {
    border: none;
  }

  div.gallery .popup-image img:hover { 
    border: none;
    box-shadow: none;
  }

  .remove-selected-images {
    cursor: pointer;
  }

  div.featuredImage {
    margin: 5px;
    border: 1px solid green;
    float: left;
    width: 200px;
  }

  div.featuredImage:hover {
    border: 1px solid green;
  }

  div.featuredImage img {
    width: 100%;
    height: 140px;
  }

  div.featuredImage p {
    color: green;
  }

  .image-order {
    position: relative;
    align-items: center;
    justify-content: center;
  }

  div.desc {
    padding: 15px;
    text-align: center;
  }

  .error-msg {
    color: salmon;
  }

  .image-light-box-label{
    color: white;
  }
  