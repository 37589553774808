//
// Theme style
//

// Initialize
@import "init";

// Components
@import "./core/components/components";
@import "components/components";

// Layout
@import "./core/layout/base";
@import "layout/layout";

// Active report js CSS files
@import "@grapecity/activereports/styles/ar-js-ui.css";
@import "@grapecity/activereports/styles/ar-js-viewer.css";